import React from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAddressBook} from '@fortawesome/free-solid-svg-icons';
import stickerImage from '../../assets/images/general/custom-stickers.JPG';
import printedBox from '../../assets/images/general/box-printing.JPG';
import stackedBox from '../../assets/images/general/custom-box-order.JPG';
import './customOrder.css';

const customOrder = () => {
    return(
        <main>
            <div className="custom-order-container">
                <div className="custom-order-headline">
                    <h2>We also offer custom services as well</h2>
                    <h4>Here are a list of services that we offer</h4>
                </div>
                <div className="service-container">
                    <div className="grid-item">
                        <img src={stackedBox} alt="Custom Box Sizes"></img>
                        <h5>Custom Kraft and Carton Box Orders</h5>
                    </div>
                    <div className="grid-item">
                        <img src={stickerImage}  alt="Custom Stickers"></img>
                        <h5>Stickers Design and Printing</h5>
                    </div>
                    <div className="grid-item">
                        <img src={printedBox}  alt="Box Printing"></img>
                        <h5>Kraft and Carton Box Printing</h5>
                    </div>
                </div>
                <div className="interest-call">
                    <h5>If interested, we can be contacted at these platforms!</h5>
                    <div className="contact-section phone">
                        <Link to="/contacts">
                            <button className="btn-contacts">
                                <FontAwesomeIcon className="contacts-icon" icon={faAddressBook} size="4x"/>
                            </button>
                        </Link>
                        <h5 className="custom-padding">Contacts</h5>
                    </div>
                    <div className="contact-section tablet-desktop">
                        <Link to="/contacts">
                            <button className="btn-contacts">
                                <FontAwesomeIcon className="contacts-icon" icon={faAddressBook} size="5x"/>
                            </button>
                        </Link>
                        <h5 className="custom-padding">Contacts</h5>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default customOrder;