import React from 'react';
import {Link} from 'react-router-dom';
import './NoMatch.css';

const NoMatch = () => {
    return(
        <main>
            <div className="nomatch-container">
                <h2>Error: Page Not Found</h2>
                <Link to="/">
                    <button className="nomatch-return-button">Click here to go back to the homepage</button>
                </Link>
            </div>
        </main>
    );
}

export default NoMatch;