import React from 'react';
import './Footer.css';

const Footer = (props) =>{
    return(
        <footer className="footer-main">
            <div className="footer-container">
                <p>Copyright &copy; {props.currentYear}, Brycen Packaging</p>
            </div>
        </footer>
    );
}

export default Footer;