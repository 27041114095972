import React from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBoxes, faAddressBook, faScroll} from '@fortawesome/free-solid-svg-icons';
import {faFacebook} from '@fortawesome/free-brands-svg-icons';
import './Homepage.css';

const Homepage = () =>{
    
    return(
        <main>
            <div className="homepage-container">
                <h1 className="headline">Welcome to Brycen Packaging</h1>
                <h4 className="slogan">Your stop for packaging needs</h4>
                
                <div className="grid-button-display">
                    <div className="grid-item">
                        <a href={"https://www.lazada.com.my/shop/boxthree/?spm=a2o4k.pdp.seller.1.567a4678AlOxHl&itemId=983220588&channelSource=pdp"}>
                            <button className="btn-lazada"></button>
                        </a>
                        <h5>Lazada</h5>
                    </div>
                    <div className="grid-item">
                        <a href={"https://www.facebook.com/Brycen-Packaging-106204864462750/"}>
                            <button className="btn-facebook">
                                <FontAwesomeIcon className="facebook-icon" icon={faFacebook} size="3x"/>
                            </button>
                        </a>
                        <h5>Facebook</h5>
                    </div>
                    <div className="grid-item">
                        <Link to="/products">
                            <button className="btn-products">
                                <FontAwesomeIcon className="products-icon" icon={faBoxes} size="3x"/>
                            </button>
                        </Link>
                        <h5 className="custom-padding">Products</h5>
                    </div>
                    <div className="grid-item">
                        <a href={"https://shopee.com.my/shop/271445756/"}>
                            <button className="btn-shopee"></button>
                        </a>
                        <h5>Shopee</h5>
                    </div>
                    <div className="grid-item">
                        <Link to="/contacts">
                            <button className="btn-contacts">
                                <FontAwesomeIcon className="contacts-icon" icon={faAddressBook} size="3x"/>
                            </button>
                        </Link>
                        <h5 className="custom-padding">Contacts</h5>
                    </div>
                    <div className="grid-item">
                        <Link to="/customOrder">
                            <button className="btn-custom-order">
                                <FontAwesomeIcon className="custom-order-icon" icon={faScroll} size="3x"/>
                            </button>
                        </Link>
                        <h5 className="custom-padding">Custom<br/> Order</h5>
                    </div>
                </div>

                <div className="grid-button-display-tablet">
                    <div className="grid-item">
                        <a href={"https://www.lazada.com.my/shop/boxthree/?spm=a2o4k.pdp.seller.1.567a4678AlOxHl&itemId=983220588&channelSource=pdp"}>
                            <button className="btn-lazada"></button>
                        </a>
                        <h5>Lazada</h5>
                    </div>
                    <div className="grid-item">
                        <a href={"https://www.facebook.com/Brycen-Packaging-106204864462750/"}>
                            <button className="btn-facebook">
                                <FontAwesomeIcon className="facebook-icon" icon={faFacebook} size="5x"/>
                            </button>
                        </a>
                        <h5>Facebook</h5>
                    </div>
                    <div className="grid-item">
                        <Link to="/products">
                            <button className="btn-products">
                                <FontAwesomeIcon className="products-icon" icon={faBoxes} size="5x"/>
                            </button>
                        </Link>
                        <h5 className="custom-padding">Products</h5>
                    </div>
                    <div className="grid-item">
                        <a href={"https://shopee.com.my/shop/271445756/"}>
                            <button className="btn-shopee"></button>
                        </a>
                        <h5>Shopee</h5>
                    </div>
                    <div className="grid-item">
                        <Link to="/contacts">
                            <button className="btn-contacts">
                                <FontAwesomeIcon className="contacts-icon" icon={faAddressBook} size="5x"/>
                            </button>
                        </Link>
                        <h5 className="custom-padding">Contacts</h5>
                    </div>
                    <div className="grid-item">
                        <Link to="/customOrder">
                            <button className="btn-custom-order">
                                <FontAwesomeIcon className="custom-order-icon" icon={faScroll} size="4x"/>
                            </button>
                        </Link>
                        <h5 className="custom-padding">Custom<br/> Order</h5>
                    </div>
                </div>

                <ul className="button-display">
                    <li>
                        <a href={"https://www.lazada.com.my/shop/boxthree/?spm=a2o4k.pdp.seller.1.567a4678AlOxHl&itemId=983220588&channelSource=pdp"}>
                            <button className="btn-lazada"></button>
                        </a>
                        <h5>Lazada</h5>
                    </li>
                    <li>
                        <a href={"https://www.facebook.com/Brycen-Packaging-106204864462750/"}>
                            <button className="btn-facebook">
                                <FontAwesomeIcon className="facebook-icon" icon={faFacebook} size="5x"/>
                            </button>
                        </a>
                        <h5>Facebook</h5>
                    </li>
                    <li>
                        <Link to="/products">
                            <button className="btn-products">
                                <FontAwesomeIcon className="products-icon" icon={faBoxes} size="5x"/>
                            </button>
                        </Link>
                        <h5 className="custom-padding">Products</h5>
                    </li>
                    <li>
                        <Link to="/customOrder">
                            <button className="btn-custom-order">
                                <FontAwesomeIcon className="custom-order-icon" icon={faScroll} size="5x"/>
                            </button>
                        </Link>
                        <h5 className="custom-padding">Custom Order</h5>
                    </li>
                    <li>
                        <Link to="/contacts">
                            <button className="btn-contacts">
                                <FontAwesomeIcon className="contacts-icon" icon={faAddressBook} size="5x"/>
                            </button>
                        </Link>
                        <h5 className="custom-padding">Contacts</h5>
                    </li>
                    <li>
                        <a href={"https://shopee.com.my/shop/271445756/"}>
                            <button className="btn-shopee"></button>
                        </a>
                        <h5>Shopee</h5>
                    </li>
                </ul>
            </div>
        </main>
    );
}

export default Homepage;