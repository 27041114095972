import React from 'react';
import Card from '../../components/Card/Card';

import './Product.css';

const Product = (props) => {
    const products = props.products;
    return(
        <main>
            <div className="product-container">
                <div className="center-container">
                    {products.map(item => (
                        <div className="product-card" key={item.productId}>
                            <Card productDetails={item}/>
                        </div>
                    ))}
                </div>
            </div>
        </main>
    );
}

export default Product;