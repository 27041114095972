import React from 'react';
import {Link} from 'react-router-dom';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import './NavigationBar.css';

const NavigationBar = (props) =>{
    return(
        <header className="navigationbar-header">
            <nav className="navigationbar-nav">
                <div className="navigationbar-burger">
                    <DrawerToggleButton drawerToggleHandler={props.drawerClickHandler}/>
                </div>
                <div className="navigationbar-logo">
                    <Link to="/">
                        <button className="navigationbar-button"></button>
                    </Link>
                </div>
                <div className="spacer"></div>
                <ul className="navigationbar-links">
                    <li className="navigationbar-item">
                        <Link to="/products">Products</Link>
                    </li>
                    <li className="navigationbar-item">
                        <Link to="/customOrder">Custom Orders</Link>
                    </li>
                    <li className="navigationbar-item">
                        <Link to="/contacts">Contacts</Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default NavigationBar;