import React from 'react';
import {Redirect} from 'react-router-dom';
import ItemCarousel from '../../../components/Carousel/Carousel';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './ItemPage.css';

function itemPage({match, itemDetails}){
    const currentProduct = itemDetails.find(item => item.productId === match.params.id);
    
    if(currentProduct === undefined){
        return(
            <main>
                <Redirect to="/404"/>
            </main>
        );
    }
    
    return(
        <main className="itempage-main-container">
            <div className="itempage-container" key={match.params.id}>
                <div className="itempage-carousel">
                    <ItemCarousel currentItem={currentProduct}/>
                </div>

                <div className="itempage-sidecontent phone">
                    <div className = "itempage-title">
                        <h3>{currentProduct.productName}</h3>
                    </div>
                    <div className = "itempage-description">
                        <div className = "itempage-description-title">
                            <h3>Description</h3>
                        </div>
                        <ul>
                            {
                                currentProduct.productDescription.map(description => (
                                    <li>
                                        {description}
                                    </li>
                                ))
                            }
                        </ul>
                        <div className="itempage-button-redirect">
                        <h4>Available at:</h4>
                        <div className="button-list">
                            <a href={"https://www.lazada.com.my/shop/boxthree/?spm=a2o4k.pdp.seller.1.567a4678AlOxHl&itemId=983220588&channelSource=pdp"}>
                                <button className="button-list-lazada"></button>
                            </a>
                            <a href={"https://shopee.com.my/shop/271445756/"}>
                                <button className="button-list-shopee"></button>
                            </a>
                        </div>
                    </div>
                    </div>
                    
                </div>

                <div className="itempage-sidecontent tablet">
                    <div className = "itempage-title">
                        <h3>{currentProduct.productName}</h3>
                    </div>
                    <div className = "itempage-description">
                        <div className = "itempage-description-title">
                            <h3>Description</h3>
                        </div>
                        <ul>
                            {
                                currentProduct.productDescription.map(description => (
                                    <li>
                                        {description}
                                    </li>
                                ))
                            }
                        </ul>
                        <div className="itempage-button-redirect">
                        <h4>Available at:</h4>
                        <div className="button-list">
                            <a href={"https://www.lazada.com.my/shop/boxthree/?spm=a2o4k.pdp.seller.1.567a4678AlOxHl&itemId=983220588&channelSource=pdp"}>
                                <button className="button-list-lazada"></button>
                            </a>
                            <a href={"https://shopee.com.my/shop/271445756/"}>
                                <button className="button-list-shopee"></button>
                            </a>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="itempage-sidecontent desktop">
                    <div className = "itempage-title">
                        <h3>{currentProduct.productName}</h3>
                    </div>
                    <div className = "itempage-description">
                        <div className = "itempage-description-title">
                            <h3>Description</h3>
                        </div>
                        <ul>
                            {
                                currentProduct.productDescription.map(description => (
                                    <li>
                                        {description}
                                    </li>
                                ))
                            }
                        </ul>
                        <div className="itempage-button-redirect">
                        <h4>Available at:</h4>
                        <div className="button-list">
                            <a href={"https://www.lazada.com.my/shop/boxthree/?spm=a2o4k.pdp.seller.1.567a4678AlOxHl&itemId=983220588&channelSource=pdp"}>
                                <button className="button-list-lazada"></button>
                            </a>
                            <a href={"https://shopee.com.my/shop/271445756/"}>
                                <button className="button-list-shopee"></button>
                            </a>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default itemPage;