import React from 'react';
import {Link} from 'react-router-dom';
import './SideDrawer.css';

const sideDrawer = (props) =>{

    let drawerClasses = 'side-drawer';
    
    if (props.showDrawer){
        drawerClasses = 'side-drawer open';
    }

    return(
        <nav className={drawerClasses}>
            <div className="side-drawer-title">
                <Link to='/' onClick={props.drawerCloseClickHandler}>
                    <button className="side-drawer-logo"></button>
                </Link>
            </div>
            <ul className="side-drawer-items">
                <li>
                    <Link to='/' onClick={props.drawerCloseClickHandler}>Home</Link>
                </li>
                <li>
                    <Link to='/products' onClick={props.drawerCloseClickHandler}>Products</Link>
                </li>
                <li>
                    <Link to='/customOrder' onClick={props.drawerCloseClickHandler}>Custom Orders</Link>
                </li>
                <li>
                    <Link to='/contacts' onClick={props.drawerCloseClickHandler}>Contacts</Link>
                </li>
            </ul>
            <ul className="side-drawer-links">
                <h5>Check out Brycen Packaging from these platforms!</h5>
                <li>
                    <a href={"https://www.lazada.com.my/shop/boxthree/?spm=a2o4k.pdp.seller.1.567a4678AlOxHl&itemId=983220588&channelSource=pdp"}>
                        <button className="side-drawer-lazada"></button>
                    </a>
                </li>
                <li>
                    <a href={"https://shopee.com.my/shop/271445756/"}>
                        <button className="side-drawer-shopee"></button>
                    </a>
                </li>
                
            </ul>
        </nav>
    );
}

export default sideDrawer;