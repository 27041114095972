import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebook, faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import './Contact.css';

const Contact = () => {
    return(
        <main className="main-contact-container">
            <div className="contact-container">
                <h4 className="contact-description">If there are any questions or issues, we can be contacted at these following platforms</h4>
                <div className="contact-links">

                    <div className="contact-links-list phone">
                        <div className="grid-item">
                            <a href={"https://www.lazada.com.my/shop/boxthree/?spm=a2o4k.pdp.seller.1.567a4678AlOxHl&itemId=983220588&channelSource=pdp"}>
                                <button className="contact-links-list-lazada"></button>
                            </a>
                            <h5>Lazada Chat : Brycen Packaging</h5>
                        </div>
                        <div className="grid-item">
                            <a href={"https://shopee.com.my/shop/271445756/"}>
                                <button className="contact-links-list-shopee"></button>
                            </a>
                            <h5>Shopee Chat : Brycen Packaging</h5>
                        </div>
                        <div className="grid-item">
                            <a href={"https://www.facebook.com/Brycen-Packaging-106204864462750/"}>
                                <button className="contact-links-list-facebook-phone">
                                    <FontAwesomeIcon icon={faFacebook} size="4x"/>
                                </button>
                            </a>
                            <h5>Facebook : Brycen Packaging</h5>
                        </div>
                        <div className="grid-item">
                            <a href={" https://wa.me/60173666298"}>
                                <button className="contact-links-list-whatsapp-phone">
                                    <FontAwesomeIcon className="whatsapp-icon" icon={faWhatsapp} size="4x"/>
                                </button>
                            </a>
                            <h5>Whatsapp : Brycen Packaging</h5>
                        </div>
                        <div className="grid-item">
                            <button className="contact-links-list-email-phone">
                                <FontAwesomeIcon icon={faEnvelope} size="5x"/>
                            </button>
                            <h5>Email : brycen.packaging@gmail.com</h5>
                        </div>
                    </div>

                    <div className="contact-links-list tablet-desktop">
                        <div className="grid-item">
                            <a href={"https://www.lazada.com.my/shop/boxthree/?spm=a2o4k.pdp.seller.1.567a4678AlOxHl&itemId=983220588&channelSource=pdp"}>
                                <button className="contact-links-list-lazada"></button>
                            </a>
                            <h5>Lazada Chat : Brycen Packaging</h5>
                        </div>
                        <div className="grid-item">
                            <a href={"https://shopee.com.my/shop/271445756/"}>
                                <button className="contact-links-list-shopee"></button>
                            </a>
                            <h5>Shopee Chat : Brycen Packaging</h5>
                        </div>
                        <div className="grid-item">
                            <a href={"https://www.facebook.com/Brycen-Packaging-106204864462750/"}>
                                <button className="contact-links-list-facebook">
                                    <FontAwesomeIcon icon={faFacebook} size="5x"/>
                                </button>
                            </a>
                            <h5>Facebook : Brycen Packaging</h5>
                        </div>
                        <div className="grid-item">
                            <a href={" https://wa.me/60173666298"}>
                                <button className="contact-links-list-whatsapp">
                                    <FontAwesomeIcon className="whatsapp-icon" icon={faWhatsapp} size="5x"/>
                                </button>
                            </a>
                            <h5>Whatsapp : Brycen Packaging</h5>
                        </div>
                        <div className="grid-item">
                            <FontAwesomeIcon icon={faEnvelope} size="6x"/>
                            <h5>Email : brycen.packaging@gmail.com</h5>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Contact;