import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import Homepage from './pages/Homepage/Homepage';
import Contact from './pages/Contact/Contact';
import Product from './pages/Product/Product';
import ItemPage from './pages/Product/ItemPage/ItemPage';
import CustomOrder from './pages/CustomOrder/customOrder';
import NoMatch from './pages/NoMatch/NoMatch';
import NavigationBar from './components/NavigationBar/NavigationBar';
import SideDrawer from './components/SideDrawer/SideDrawer';
import Backdrop from './components/Backdrop/Backdrop';
import Footer from './components/Footer/Footer';
import './App.css';

class App extends Component{
  state = {
    sideDrawerOpen: false
  };

  products = [
    {
      productName: 'Brycen BC1021',
      productId: 'bc1021',
      productDescription: [
          "Box Size (Length x Width x Height): 26cm x 16cm x 24cm.",
          "Material: B Flute Corrugated Material",
          "Material Thickness: 3.175mm",
          "Good crush resistance",
          "Good puncture resistance",
          "Suitable for printing",
          "100% Made Locally in Malaysia",
          "Available in quantities of 10 pieces and 20 pieces per unit",
          "We will ship your orders within 1 ~ 2 business days",
          "(Disclaimer)* An iPhone XS Max was used as a comparison to this box"
      ]
    },
    {
        productName: 'Brycen BC2021',
        productId: 'bc2021',
        productDescription: [
          "Box Size (Length x Width x Height): 13cm x 11cm x 9cm.",
          "Material: Kraft Paper E Flute",
          "Material Thickness: 1.5mm",
          "Good crush resistance",
          "Suitable for printing",
          "100% Made Locally in Malaysia",
          "Available in quantities of 10 pieces and 20 pieces per unit",
          "We will ship your orders within 1 ~ 2 business days",
          "(Disclaimer)* An iPhone XS Max was used as a comparison to this box"
        ]
    },
    {
        productName: 'Brycen BC2022',
        productId: 'bc2022',
        productDescription: [
          "Box Size (Length x Width x Height): 18cm x 11cm x 9cm.",
          "Material: Kraft Paper E Flute",
          "Material Thickness: 1.5mm",
          "Good crush resistance",
          "Suitable for printing",
          "100% Made Locally in Malaysia",
          "Available in quantities of 10 pieces and 20 pieces per unit",
          "We will ship your orders within 1 ~ 2 business days",
          "(Disclaimer)* An iPhone XS Max was used as a comparison to this box"
        ]
    },
    {
        productName: 'Brycen BC2023',
        productId: 'bc2023',
        productDescription: [
          "Box Size (Length x Width x Height): 23cm x 15cm x 13cm.",
          "Material: Kraft Paper E Flute",
          "Material Thickness: 1.5mm",
          "Good crush resistance",
          "Suitable for printing",
          "100% Made Locally in Malaysia",
          "Available in quantities of 10 pieces and 20 pieces per unit",
          "We will ship your orders within 1 ~ 2 business days",
          "(Disclaimer)* An iPhone XS Max was used as a comparison to this box"
        ]
    },
    {
      productName: 'Brycen Fragile Stickers',
      productId: 'bcfragile',
      productDescription: [
        "Sticker Size (Length x Height): 12.7cm x 8.9cm.",
        "Colour: Red and Yellow",
        "Sticker Contents: Fragile, Keep Dry, Upwards, Don't Trample, Handle With Care, and Don't Fall Not Pressure Warnings.",
        "Compatible Box Model: Brycen BC1021, Brycen BC2022, Brycen BC2023",
        "100% Made Locally in Malaysia",
        "Available in quantities of 100 per unit",
        "We will ship your orders within 1 ~ 2 business days",
      ]
    },
  ]


  info = {
    year: new Date().getFullYear()
  };


  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen};
    });
  }

  drawerCloseClickHander = () => {
    this.setState({sideDrawerOpen: false});
  }

  backdropClickHandler = () => {
    this.setState({sideDrawerOpen: false});
  }

  render(){
    let backdrop;

    if(this.state.sideDrawerOpen){
      backdrop = <Backdrop backdropHandler={this.backdropClickHandler}/>;
    }

    return(
      <React.Fragment>
        <div className="layout">
          <NavigationBar drawerClickHandler = {this.drawerToggleClickHandler}/>
          <SideDrawer showDrawer={this.state.sideDrawerOpen} drawerCloseClickHandler={this.drawerCloseClickHander}/>
          {backdrop}
          <div className="main-container">
            <Switch>
              <Route path ="/" exact component={Homepage}></Route>
              <Route path = "/contacts" component={Contact}></Route>
              <Route path = "/customOrder" exact component={CustomOrder}></Route>
              <Route path = "/products" exact render={ (props) => <Product {...props} products={this.products}/>}></Route>
              <Route path = "/products/:id" render={ (props) => <ItemPage {...props} itemDetails={this.products}/>}></Route>
              <Route component={NoMatch}/>
            </Switch>
          </div>
          <Footer currentYear = {this.info.year}/>
        </div>
      </React.Fragment>
    );
  }
}

export default App;
