import React from 'react';
import {Link} from 'react-router-dom';
import bc1021FrontView from '../../assets/images/bc1021/front-view.jpg';
import bc2021FrontView from '../../assets/images/bc2021/front-view.jpg';
import bc2022FrontView from '../../assets/images/bc2022/front-view.jpg';
import bc2023FrontView from '../../assets/images/bc2023/front-view.jpg';
import bcfragileFrontView from '../../assets/images/bcfragile/front-view.jpg';
import './Card.css';

const Card = (props) =>{
    const currentItem = props.productDetails;
    let imageUrl;
    if(currentItem.productId === "bc2021"){
        imageUrl = bc2021FrontView;
    }
    else if (currentItem.productId === "bc2022"){
        imageUrl = bc2022FrontView;
    }
    else if (currentItem.productId === "bc2023"){
        imageUrl = bc2023FrontView;
    }
    else if (currentItem.productId === "bc1021"){
        imageUrl = bc1021FrontView;
    }
    else if (currentItem.productId === "bcfragile"){
        imageUrl = bcfragileFrontView;
    }
    
    return(
        <div className="card">
            <div className="card-image" style={{
                background: `url(${imageUrl})`,
                backgroundSize:"cover",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px"
                }}></div>
            <div className="card-body">
                <div className="card-text">
                    <div className="card-headline">
                        <h5>{currentItem.productName}</h5>
                    </div>
                    <div className="card-description">
                        <ul>
                            <li>{currentItem.productDescription[0]}</li>
                            <li>Available in Lazada and Shopee.</li>
                            <li>You can learn more about the product by clicking "Learn More".</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="card-redirection">
                <div className="redirection">
                    <a href={"https://www.lazada.com.my/shop/boxthree/?spm=a2o4k.pdp.seller.1.567a4678AlOxHl&itemId=983220588&channelSource=pdp"}>Lazada</a>
                </div>
                <div className="redirection border">
                    <a href={"https://shopee.com.my/shop/271445756/"}>Shopee</a>
                </div>
                <div className="redirection">
                    <li>
                         <Link to={
                            {
                                pathname: `/products/${props.productDetails.productId}`
                            }
                        } >Learn More</Link>
                    </li>
                </div>
            </div>
        </div>
    );
}

export default Card;