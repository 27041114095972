import React from 'react';
import Slider from 'react-slick';
import bc1021FrontView from '../../assets/images/bc1021/front-view.jpg';
import bc1021PhoneView from '../../assets/images/bc1021/iphone-box-view.jpg';
import bc1021DimensionView from '../../assets/images/bc1021/item-dimension.jpg';
import bc1021ItemView from '../../assets/images/bc1021/item-view.jpg';
import bc1021MaterialView from '../../assets/images/bc1021/material-view.jpg';
import bc2021FrontView from '../../assets/images/bc2021/front-view.jpg';
import bc2021PhoneView from '../../assets/images/bc2021/iphone-box-view.jpg';
import bc2021DimensionView from '../../assets/images/bc2021/item-dimension.png';
import bc2021ItemView from '../../assets/images/bc2021/item-view.jpg';
import bc2021MaterialView from '../../assets/images/bc2021/material-view.jpg';
import bc2021SnackBoxView from '../../assets/images/bc2021/snack-box.jpg';
import bc2021SnackFitView from '../../assets/images/bc2021/snack-fit.jpg';
import bc2022FrontView from '../../assets/images/bc2022/front-view.jpg';
import bc2022PhoneView from '../../assets/images/bc2022/iphone-box-view.jpg';
import bc2022DimensionView from '../../assets/images/bc2022/item-dimension.png';
import bc2022ItemView from '../../assets/images/bc2022/item-view.jpg';
import bc2022MaterialView from '../../assets/images/bc2022/material-view.jpg';
import bc2022SnackBoxView from '../../assets/images/bc2022/snack-box.jpg';
import bc2022SnackFitView from '../../assets/images/bc2022/snack-fit.jpg';
import bc2023FrontView from '../../assets/images/bc2023/front-view.jpg';
import bc2023PhoneView from '../../assets/images/bc2023/iphone-box-view.jpg';
import bc2023DimensionView from '../../assets/images/bc2023/item-dimension.png';
import bc2023ItemView from '../../assets/images/bc2023/item-view.jpg';
import bc2023MaterialView from '../../assets/images/bc2023/material-view.jpg';
import bc2023SnackBoxView from '../../assets/images/bc2023/snack-box.jpg';
import bc2023SnackFitView from '../../assets/images/bc2023/snack-fit.jpg';
import bcfragileFrontView from '../../assets/images/bcfragile/front-view.jpg';
import bcfragileDimensionView from '../../assets/images/bcfragile/item-dimension.jpg';
import bcfragileBC1021View from '../../assets/images/bcfragile/sticker-bc1021-view.jpg';
import bcfragileBC2022View from '../../assets/images/bcfragile/sticker-bc2022-view.jpg';
import bcfragileBC2023View from '../../assets/images/bcfragile/sticker-bc2023-view.jpg';
import './Carousel.css';

const ItemCarousel = (props) => {
  
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    fade: true,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0
  };

  const phoneSettings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    fade: true,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false
  };

  const currentItem = props.currentItem;
  const images = [
    {
      itemId: 'bc1021',
      imageLinksAlts: [
        {imageLink: bc1021FrontView, imageAlt: "Front View"},
        {imageLink: bc1021PhoneView, imageAlt: "iPhone Box View"},
        {imageLink: bc1021DimensionView, imageAlt: "Item Dimension"},
        {imageLink: bc1021ItemView, imageAlt: "Item View"},
        {imageLink: bc1021MaterialView, imageAlt: "Material View"}
      ]
    },
    {
      itemId: 'bc2021',
      imageLinksAlts: [
        {imageLink: bc2021FrontView, imageAlt: "Front View"},
        {imageLink: bc2021PhoneView, imageAlt: "iPhone Box View"},
        {imageLink: bc2021DimensionView, imageAlt: "Item Dimension"},
        {imageLink: bc2021ItemView, imageAlt: "Item View"},
        {imageLink: bc2021MaterialView, imageAlt: "Material View"},
        {imageLink: bc2021SnackBoxView, imageAlt: "Snack Box View"},
        {imageLink: bc2021SnackFitView, imageAlt: "Snack Fit View"}
      ]
    },
    {
      itemId: 'bc2022',
      imageLinksAlts: [
        {imageLink: bc2022FrontView, imageAlt: "Front View"},
        {imageLink: bc2022PhoneView, imageAlt: "iPhone Box View"},
        {imageLink: bc2022DimensionView, imageAlt: "Item Dimension"},
        {imageLink: bc2022ItemView, imageAlt: "Item View"},
        {imageLink: bc2022MaterialView, imageAlt: "Material View"},
        {imageLink: bc2022SnackBoxView, imageAlt: "Snack Box View"},
        {imageLink: bc2022SnackFitView, imageAlt: "Snack Fit View"}
      ]
    },
    {
      itemId: 'bc2023',
      imageLinksAlts: [
        {imageLink: bc2023FrontView, imageAlt: "Front View"},
        {imageLink: bc2023PhoneView, imageAlt: "iPhone Box View"},
        {imageLink: bc2023DimensionView, imageAlt: "Item Dimension"},
        {imageLink: bc2023ItemView, imageAlt: "Item View"},
        {imageLink: bc2023MaterialView, imageAlt: "Material View"},
        {imageLink: bc2023SnackBoxView, imageAlt: "Snack Box View"},
        {imageLink: bc2023SnackFitView, imageAlt: "Snack Fit View"}
      ]
    },
    {
      itemId: 'bcfragile',
      imageLinksAlts: [
        {imageLink: bcfragileFrontView, imageAlt: "Front View"},
        {imageLink: bcfragileDimensionView, imageAlt: "Item Dimension"},
        {imageLink: bcfragileBC2022View, imageAlt: "BC2022 Sticker View"},
        {imageLink: bcfragileBC2023View, imageAlt: "BC2023 Sticker View"},
        {imageLink: bcfragileBC1021View, imageAlt: "BC1021 Sticker View"}
      ]
    }
  ];

  const itemImages = images.find(item => item.itemId === currentItem.productId);
  return (
    <div className="carousel-container">
      <div className="phone">
        <Slider {...phoneSettings}>
          {itemImages.imageLinksAlts.map(imageLinkAlt => (
            <div key={itemImages.itemId}>
              <img src={imageLinkAlt.imageLink} alt={imageLinkAlt.imageAlt}></img>
            </div>
          ))}
        </Slider>
      </div>
      <div className="tablet-desktop">
        <Slider {...settings}>
          {itemImages.imageLinksAlts.map(imageLinkAlt => (
            <div key={itemImages.itemId}>
              <img src={imageLinkAlt.imageLink} alt={imageLinkAlt.imageAlt}></img>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );

};

export default ItemCarousel;
